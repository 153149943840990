import {
  OriginatingProduct,
  RewardSplit,
  OfferRenderType,
  RewardType,
} from 'Types';

type Step = {
  join: string;
  wager: string;
  reward: string;
};

export type DefaultCopy = {
  welcomeText: string;
  welcomeTextReward: string;
  headerTextSubtitle: string;
  referralMessage: string;
  noReferralMessage: string;
  landingWelcome: string;
  landingWelcomeReward: string;
  landingHowitWorks: string;
  landingConditionsText: string;
  joinButton: string;
  landingHowItWorksSbkStep: Step;
  landingHowItWorksCasStep: Step;
};

type CopyOverride = Partial<DefaultCopy>;

type RewardSplitObject = Partial<
  {
    [key in RewardSplit]: CopyOverride;
  }
>;

type RewardTypeObject = Partial<
  {
    [key in RewardType]: RewardSplitObject;
  }
>;

type OfferProductObject = {
  [key in OfferRenderType]: RewardTypeObject;
};

type Copy = {
  [key in OriginatingProduct]: OfferProductObject;
};

export const defaultCopy: DefaultCopy = {
  welcomeText: 'Refer a friend \nand get',
  welcomeTextReward: ' $%referrerRewardAmount%',
  headerTextSubtitle: '',
  referralMessage: '',
  noReferralMessage: '',
  landingWelcome: 'Join %referrerUsername% on FanDuel and get ',
  landingWelcomeReward: '$%refereeRewardAmount% to play!',
  landingHowitWorks: '',
  landingConditionsText: '',
  joinButton: 'Join FanDuel',
  landingHowItWorksSbkStep: {
    join: 'Join\nSportsbook',
    wager: 'Wager and Settle\na $%sbkWagerAmount%+ Bet',
    reward: 'Get $%sbkRefereeRewardAmount% \nin Bonus Bets',
  },
  landingHowItWorksCasStep: {
    join: 'Join\nCasino',
    wager: 'Play Through\n$%casinoWagerAmount%+',
    reward: 'Get $%casinoRefereeRewardAmount%\nin Bonus',
  },
};

export const copy: Copy = {
  SB: {
    hybrid: {
      EXTRA_WALLET_REWARD: {
        symmetric: {
          welcomeText: 'You and a \nfriend can get',
          welcomeTextReward: '\n$%referrerRewardAmount% each!',
          headerTextSubtitle:
            'Get $%sbkReferrerRewardAmount% in Sportsbook Bonus \nBets and $%casinoReferrerRewardAmount% in Casino Bonus',
          landingHowitWorks:
            'Get $%sbkRefereeRewardAmount% in Bonus Bets when you place $%sbkWagerAmount%+ wager on FanDuel Sportsbook \nGet $%casinoRefereeRewardAmount% in Casino Bonus when you play through $%casinoWagerAmount%+ on FanDuel Casino',
          landingConditionsText:
            '21+ (18+ D.C.) in select states. Sportsbook Bonus Bets and Casino Bonus are non-withdrawable and expire after 7 days. Restrictions apply. See terms at ',
          landingHowItWorksSbkStep: {
            join: 'Join\nSportsbook',
            wager: 'Wager and Settle\na $%sbkWagerAmount%+ Bet',
            reward: 'Get $%sbkRefereeRewardAmount% \nin Bonus Bets',
          },
        },
        asymmetric: {
          headerTextSubtitle: "They'll get $%sbkRefereeRewardAmount%, too!",
          landingHowitWorks:
            'Get $%sbkRefereeRewardAmount% in Bonus Bets when you place $%sbkWagerAmount%+ wager on FanDuel Sportsbook \nGet $%casinoRefereeRewardAmount% in Casino Bonus when you play through $%casinoWagerAmount%+ on FanDuel Casino',
          landingConditionsText:
            '21+ (18+ D.C.) in select states. Sportsbook Bonus Bets and Casino Bonus are non-withdrawable and expire after 7 days. Restrictions apply. See terms at ',
          landingHowItWorksSbkStep: {
            join: 'Join\nSportsbook',
            wager: 'Wager and Settle\na $%sbkWagerAmount%+ Bet',
            reward: 'Get $%sbkRefereeRewardAmount% \nin Bonus Bets',
          },
        },
        zeroRecruit: {
          welcomeText: '',
          welcomeTextReward:
            'Get $%referrerRewardAmount% in\nBonus Bets &\nCasino Bonus',
          landingWelcome: 'Join %referrerUsername% on FanDuel and get a ',
          landingWelcomeReward: 'new user offer!',
          landingHowitWorks: '',
          landingConditionsText:
            '21+ (18+ D.C.) in select states. Sportsbook Bonus Bets and Casino Bonus are non-withdrawable and expire after 7 days. Restrictions apply. See terms at ',
          landingHowItWorksSbkStep: {
            join: 'Join\nSportsbook',
            wager: 'Place your \nfirst bet',
            reward: 'Get a \nnew user \noffer',
          },
          landingHowItWorksCasStep: {
            join: 'Join\nCasino',
            wager: 'Play Through\n$%casinoWagerAmount%+',
            reward: 'Get a \nnew user \nbonus',
          },
        },
      },
    },
    standAlone: {
      EXTRA_WALLET_REWARD: {
        symmetric: {
          welcomeText: 'You and a \nfriend can get',
          welcomeTextReward: '\n$%referrerRewardAmount% each!',
          landingWelcomeReward: '$%sbkRefereeRewardAmount% in Bonus Bets!',
          landingHowitWorks:
            'Join FanDuel Sportsbook, wager $%sbkWagerAmount%+ of your own cash, and get $%sbkRefereeRewardAmount% in Bonus Bets!',
          landingConditionsText:
            '21+ (18+ D.C.) in select states. Sportsbook Bonus Bets are non-withdrawable and expire after 7 days. Restrictions apply. See terms at ',
          joinButton: 'Join Sportsbook',
        },
        asymmetric: {
          headerTextSubtitle: "They'll get $%sbkRefereeRewardAmount%, too!",
          landingWelcomeReward: '$%sbkRefereeRewardAmount% in Bonus Bets!',
          landingHowitWorks:
            'Join FanDuel Sportsbook, wager $%sbkWagerAmount%+ of your own cash, and get $%sbkRefereeRewardAmount% in Bonus Bets!',
          landingConditionsText:
            '21+ (18+ D.C.) in select states. Sportsbook Bonus Bets are non-withdrawable and expire after 7 days. Restrictions apply. See terms at ',
          joinButton: 'Join Sportsbook',
        },
        zeroRecruit: {
          welcomeText: '',
          welcomeTextReward: 'Get $%referrerRewardAmount% in\nBonus Bets',
          landingWelcome: 'Join %referrerUsername% on FanDuel and get a ',
          landingWelcomeReward: 'new user offer!',
          landingHowitWorks: '',
          landingConditionsText:
            '21+ (18+ D.C.) in select states. Sportsbook Bonus Bets are non-withdrawable and expire after 7 days. Restrictions apply. See terms at ',
          joinButton: 'Join Sportsbook',
          landingHowItWorksSbkStep: {
            join: 'Join\nSportsbook',
            wager: 'Place your \nfirst bet',
            reward: 'Get a \nnew user \noffer',
          },
        },
      },
      PRICE_BOOST_REWARD: {
        symmetric: {
          welcomeText: 'You and a friend \ncan get',
          welcomeTextReward:
            ' (%sbkReferrerRewardAmount%) %referrerPercentage%% \nProfit Boost \nTokens each ',
          landingWelcome:
            'Join %referrerUsername% on FanDuel Sportsbook and \n',
          landingWelcomeReward:
            'get (%sbkRefereeRewardAmount%) %refereePercentage%% Profit Boost Tokens!',
          landingHowitWorks:
            'Join FanDuel Sportsbook, wager $%sbkWagerAmount%+ of your own cash, and get (%sbkRefereeRewardAmount%) %refereePercentage%% Profit Boost Tokens!',
          landingConditionsText:
            '21+ (18+ D.C.) in select states. Sportsbook bonus bets, Sportsbook Profit Boost Tokens, and Casino site credit are non-withdrawable. Bonus bets and Casino site credit expire 7 days after receipt. Restrictions apply. See terms including any Profit Boost Token expiration at ',
          joinButton: 'Join Sportsbook',
          landingHowItWorksSbkStep: {
            join: 'Join\nSportsbook',
            wager: 'Wager and Settle\na $%sbkWagerAmount%+ Bet',
            reward:
              'Get (%sbkRefereeRewardAmount%) %refereePercentage%% Profit Boost Tokens!',
          },
        },
        zeroRecruit: {
          welcomeText: '',
          welcomeTextReward: `Get (%sbkReferrerRewardAmount%) %referrerPercentage%% \nProfit Boost \nTokens`,
          landingWelcome:
            'Join %referrerUsername% on FanDuel and get a ',
          landingWelcomeReward: 'new user offer!',
          landingConditionsText:
            '21+ (18+ D.C.) in select states. Sportsbook Bonus Bets are non-withdrawable and expire after 7 days. Restrictions apply. See terms at ',
          joinButton: 'Join Sportsbook',
          landingHowItWorksSbkStep: {
            join: 'Join\nSportsbook',
            wager: 'Place your \nfirst bet',
            reward: 'Get a \nnew user \noffer',
          },
        },
      },
    },
  },
  CASINO: {
    standAlone: {
      BONUS_AWARDING_REWARD: {
        symmetric: {
          welcomeText: 'You and a \nfriend can get',
          welcomeTextReward: '\n$%referrerRewardAmount% each!',
          referralMessage:
            "Friends who joined before June 2023 won't appear here.",
          noReferralMessage: "Friends who joined before June 2023 won't.",
          landingWelcomeReward: '$%casinoRefereeRewardAmount% to play!',
          landingHowitWorks:
            'Get $%casinoRefereeRewardAmount% in Casino Bonus when you play through $%casinoWagerAmount%+ on FanDuel Casino',
          landingConditionsText:
            '21+ in select states. Casino Bonus is non-withdrawable and expires after 14 days. Restrictions apply. See terms at ',
        },
        asymmetric: {
          headerTextSubtitle: "They'll get $%casinoRefereeRewardAmount%, too!",
          referralMessage:
            "Friends who joined before June 2023 won't appear here.",
          noReferralMessage: "Friends who joined before June 2023 won't.",
          landingWelcomeReward: '$%casinoRefereeRewardAmount% to play!',
          landingHowitWorks:
            'Get $%casinoRefereeRewardAmount% in Casino Bonus when you play through $%casinoWagerAmount%+ on FanDuel Casino',
          landingConditionsText:
            '21+ in select states. Casino Bonus is non-withdrawable and expires after 14 days. Restrictions apply. See terms at ',
        },
        zeroRecruit: {
          welcomeText: '',
          welcomeTextReward: 'Get $%referrerRewardAmount% in\nCasino Bonus',
          referralMessage:
            "Friends who joined before June 2023 won't appear here.",
          noReferralMessage: "Friends who joined before June 2023 won't.",
          landingWelcome: 'Join %referrerUsername% on FanDuel Casino to',
          landingWelcomeReward:
            'get up to $1000 back if you’re down after your first day and receive Bonus Spins!',
          landingConditionsText:
            '21+ in select states. Casino Bonus is non-withdrawable and expires after 14 days. Restrictions apply. See terms at ',
        },
      },
      BONUS_SPINS_REWARD: {
        symmetric: {
          welcomeText: 'You and a friend can\nget',
          welcomeTextReward: ' %casinoReferrerRewardAmount%\nBonus Spins!',
          referralMessage:
            "Friends who joined before June 2023 won't appear here.",
          noReferralMessage: "Friends who joined before June 2023 won't.",
          landingWelcomeReward: '%refereeRewardAmount% Bonus Spins',
          landingHowitWorks:
            'Get %refereeRewardAmount% Bonus Spins when you play through $%casinoWagerAmount%+ on FanDuel Casino',
          landingConditionsText:
            '21+ in select states. Casino Bonus Spins is non-withdrawable and expires after 14 days. Restrictions apply. See terms at ',
          landingHowItWorksCasStep: {
            join: 'Join\nCasino',
            wager: 'Play Through\n$%casinoWagerAmount%+',
            reward: 'Get %refereeRewardAmount% \nBonus Spins',
          },
        },
        zeroRecruit: {
          welcomeText: 'You can get\n%casinoReferrerRewardAmount% ',
          welcomeTextReward: `Bonus\nSpins!`,
          referralMessage:
            "Friends who joined before June 2023 won't appear here.",
          noReferralMessage: "Friends who joined before June 2023 won't.",
          landingWelcome: 'Join %referrerUsername% on FanDuel Casino to',
          landingWelcomeReward:
            'get up to $1000 back if you’re down after your first day and receive Bonus Spins!',
          landingConditionsText:
            '21+ in select states. Casino Bonus Spins is non-withdrawable and expires after 14 days. Restrictions apply. See terms at ',
        },
      },
    },
    hybrid: {
      BONUS_AWARDING_REWARD: {
        symmetric: {
          welcomeText: 'You and a \nfriend can get',
          welcomeTextReward: '\n$%referrerRewardAmount% each!',
          headerTextSubtitle:
            'Get $%casinoReferrerRewardAmount% in Casino Bonus \nand $%sbkReferrerRewardAmount% in Sportsbook Bonus Bets',
          referralMessage:
            "Friends who joined before June 2023 won't appear here.",
          noReferralMessage: "Friends who joined before June 2023 won't.",
          landingHowitWorks:
            'Get $%casinoRefereeRewardAmount% in Casino Bonus when you play through $%casinoWagerAmount%+ on FanDuel Casino \nGet $%sbkRefereeRewardAmount% in Bonus Bets when you place $%sbkWagerAmount%+ wager on FanDuel Sportsbook',
          landingConditionsText:
            '21+ in select states. Casino Bonus expires after 14 days and Sportsbook Bonus Bets expire after 7 days. Both are non-withdrawable. Restrictions apply. See terms at ',
        },
        asymmetric: {
          headerTextSubtitle: "They'll get $%casinoRefereeRewardAmount%, too!",
          referralMessage:
            "Friends who joined before June 2023 won't appear here.",
          noReferralMessage: "Friends who joined before June 2023 won't.",
          landingHowitWorks:
            'Get $%casinoRefereeRewardAmount% in Casino Bonus when you play through $%casinoWagerAmount%+ on FanDuel Casino \nGet $%sbkRefereeRewardAmount% in Bonus Bets when you place $%sbkWagerAmount%+ wager on FanDuel Sportsbook',
          landingConditionsText:
            '21+ in select states. Casino Bonus expires after 14 days and Sportsbook Bonus Bets expire after 7 days. Both are non-withdrawable. Restrictions apply. See terms at ',
        },
        zeroRecruit: {
          welcomeText: '',
          welcomeTextReward:
            'Get $%referrerRewardAmount% in\nCasino Bonus &\nBonus Bets',
          referralMessage:
            "Friends who joined before June 2023 won't appear here.",
          noReferralMessage: "Friends who joined before June 2023 won't.",
          landingWelcome: 'Join %referrerUsername% on FanDuel Casino to',
          landingWelcomeReward:
            'get up to $1000 back if you’re down after your first day and receive Bonus Spins!',
          landingHowitWorks: '',
          landingConditionsText:
            '21+ in select states. Casino Bonus expires after 14 days and Sportsbook Bonus Bets expire after 7 days. Both are non-withdrawable. Restrictions apply. See terms at ',
        },
      },
    },
  },
};

const canadaLandingConditionsText =
  '19+ and physically located in Ontario. Referred players must wager $25+ within 28 days after signing up. Limit 5 referrals. Bonus Bets and Casino Credit is non-withdrawable and expires after 21 days. Restrictions apply. See terms at ';

export const canadaCopy: Copy = {
  SB: {
    standAlone: {
      EXTRA_WALLET_REWARD: {
        symmetric: {
          landingConditionsText: canadaLandingConditionsText,
        },
        asymmetric: {
          landingConditionsText: canadaLandingConditionsText,
        },
        zeroRecruit: {
          landingConditionsText: canadaLandingConditionsText,
        },
      },
    },
    hybrid: {
      EXTRA_WALLET_REWARD: {
        symmetric: {
          landingConditionsText: canadaLandingConditionsText,
        },
        asymmetric: {
          landingConditionsText: canadaLandingConditionsText,
        },
        zeroRecruit: {
          landingConditionsText: canadaLandingConditionsText,
        },
      },
    },
  },
  CASINO: {
    standAlone: {
      BONUS_AWARDING_REWARD: {
        symmetric: {
          landingConditionsText: canadaLandingConditionsText,
        },
        asymmetric: {
          landingConditionsText: canadaLandingConditionsText,
        },
        zeroRecruit: {
          landingConditionsText: canadaLandingConditionsText,
        },
      },
    },
    hybrid: {
      BONUS_AWARDING_REWARD: {
        symmetric: {
          landingConditionsText: canadaLandingConditionsText,
        },
        asymmetric: {
          landingConditionsText: canadaLandingConditionsText,
        },
        zeroRecruit: {
          landingConditionsText: canadaLandingConditionsText,
        },
      },
    },
  },
};
