import { createGlobalStyle } from 'styled-components';

import ProximaNovaRegular from '../assets/fonts/ProximaNova-Regular.woff2';
import ProximaNovaCondensed from '../assets/fonts/ProximaNova-Condensed.woff2';
import ProximaNovaBold from '../assets/fonts/ProximaNova-Bold.woff2';

export const GlobalStyle = createGlobalStyle`
${({ theme }) => `
    *, :before, :after {
      box-sizing: border-box;
    }
    * {
      margin: 0;
      padding: 0;
    }
    body {
      font-family: ${theme.fontFamily};
      background-color: ${theme.colors.pageBg};
    }
    @font-face {
        font-family: 'ProximaNova';
        src: local('ProximaNova-Regular'),
            url(${ProximaNovaRegular}) format('woff2');
    }
    @font-face {
        font-family: 'ProximaNovaCondensed';
        src: local('ProximaNova-Condensed'),
            url(${ProximaNovaCondensed}) format('woff2');
    }
    @font-face {
        font-family: 'ProximaNova';
        src: local('ProximaNova-Bold'),
            url(${ProximaNovaBold}) format('woff2');
        font-style: normal;
        font-weight: bold;
    }
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
}
  `}
`;
