import { useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { Up, Down } from '../../assets/componentIcons';
import {
  Container,
  SelectedState,
  StateItem,
  StateList,
  StateMessage,
} from './StateSelectorStyles';
import { State } from '../LandingHowItWorks/LandingHowItWorks';

export type StateSelectorProps = {
  onChange: (state: State) => void;
  selectedState: State | undefined;
  states: State[]
};

export const StateSelector = ({ onChange, selectedState, states }: StateSelectorProps) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const toggleSelector = (shouldOpen: boolean) => {
    setIsOpen(shouldOpen);
  };

  const handleStateChange = (state: State) => {
    onChange(state);
    toggleSelector(false);
  };

  const stateSelectorRef = useRef<HTMLDivElement>(null);
  
  const handleKeyDown = (event: React.KeyboardEvent, state?: State) => {
    switch (event.key) {
      case 'Enter':
      case ' ':
        if (state) {
          handleStateChange(state);
        } else {
          toggleSelector(!isOpen);
        }
        event.preventDefault();
        break;
      case 'Escape':
        toggleSelector(false);
        event.preventDefault();
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        if (!isOpen) {
          toggleSelector(true);
        }
        event.preventDefault();
        break;
    }
  };

  const handleBlur = (event: React.FocusEvent) => {
    // exit the state selector when you keyboard tab past the options
    if (
      stateSelectorRef.current &&
      !stateSelectorRef.current.contains(event.relatedTarget as Node)
    ) {
      setIsOpen(false);
    }
  };

  const statusAnnouncement = `State selection ${isOpen ? 'opened' : 'closed'}`;
  const selectionAnnouncement = selectedState ? `Selected state ${selectedState.name}` : '';

  return (
    <>
      <div className="sr-only" aria-live="polite" role="status">
        {statusAnnouncement} {selectionAnnouncement}
      </div>
      <StateMessage>
        {'Casino Games are currently only available in the states below'}
      </StateMessage>
      <Container ref={stateSelectorRef} onBlur={handleBlur}>
        <SelectedState
          onClick={() => toggleSelector(!isOpen)}
          onKeyDown={handleKeyDown}
          isOpen={isOpen}
          data-testid="state-selector"
          role="combobox"
          aria-expanded={isOpen}
          aria-controls="state-listbox"
          tabIndex={0}
          aria-label={selectionAnnouncement}
        >
          {selectedState?.name || 'Please select your state'}
          <div>
            {isOpen ? (
              <Up color={theme.colors.stateSelectorArrow} />
            ) : (
              <Down color={theme.colors.stateSelectorArrow} />
            )}
          </div>
        </SelectedState>
        {isOpen && (
          <StateList
            role="listbox"
            id="state-listbox"
            aria-label="Select a state"
          >
            {states.map((state) => (
              <StateItem
                onClick={() => handleStateChange(state)}
                onKeyDown={(e) => handleKeyDown(e, state)}
                key={state.name}
                role="option"
                aria-selected={state.name === selectedState?.name}
                tabIndex={0}
              >
                {state.name}
              </StateItem>
            ))}
          </StateList>
        )}
      </Container>
    </>
  );
};
