import { datadogLogs } from '@datadog/browser-logs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import root from 'window-or-global';
import * as amplitude from '@fanduel/core-analytics';
import { Loading } from '../../lib/components';
import { ReferrerHomePage } from '../../lib/views';
import { tagScreenLoad } from '../api/gtmAPI';
import { useGetReferralDetailsByIdQuery } from '../api/rafAPI';
import { ContextWrapper } from '../contexts/AppContext';
import { setError, setHeader, setLoading } from '../redux/headerSlice';
import { RootState } from '../redux/store';
import { GtmProps } from '../types/gtmTypes';
import {
  HeaderState,
  PageProps,
  ReferrerProps,
  UserDetails,
} from '../types/headerType';
import { parseToken } from '../utils/headerService';
import { ReferralErrorPageContainer } from './ErrorPage';
import { isAccountService } from '../utils/appService';
import { getGtmSiteVersion } from '../utils/siteVersionPlatform';
import { getCookieValues } from '../utils/getCookieValues';

const errorPage = (param: PageProps): JSX.Element => {
  return (
    <ReferralErrorPageContainer theme={param.theme} gtmProps={param.gtmProps} />
  );
};

const ReferrerHomeContainer = () => {
  const [isAmplitudeLoaded, setIsAmplitudeLoaded] = useState(false);

  const theme = new URLSearchParams(root.location.search).get('theme');

  const deviceId = new URLSearchParams(root.location.search).get('deviceId');

  const dispatch = useDispatch();

  useEffect(() => {
    const waitForAmplitude = async () => {
      if (!isAmplitudeLoaded) {
        await amplitude.whenReady();
        setIsAmplitudeLoaded(true);
      }
    };
    waitForAmplitude();
  }, [isAmplitudeLoaded]);

  useEffect(() => {
    const { region, token } = getCookieValues();

    if (token && region && isAmplitudeLoaded) {
      dispatch(
        setHeader({
          token: token,
          state: region,
          ip: '',
          deviceId,
          username: parseToken(token).usn,
          loading: false,
          error: false,
        })
      );
    }
    if (!token || !region) {
      // This is a temporary fix for missing userinfo issue
      const referrerUrl = document.referrer;
      datadogLogs.logger.info('referrerUrl:' + referrerUrl);
      if (referrerUrl && isAccountService(referrerUrl)) {
        datadogLogs.logger.info('Redirecting user to:' + process.env.HOME_URL);
        // This logic is meant to be executed only when user comes from account page
        window.location.replace(process.env.HOME_URL);
        return;
      } else {
        datadogLogs.logger.error(
          'Referrer Home Page missing user info - no call to back end',
          {
            errorDetails: {
              state: region,
              ipAddress: '',
              deviceId,
              username: token ? parseToken(token).usn : 'missing',
              userID: token ? parseToken(token).sub : 'auth token missing',
            },
          }
        );
      }
      dispatch(setLoading(false));
      dispatch(setError(true));
    }
  }, [isAmplitudeLoaded]);

  const header: HeaderState = useSelector((state: RootState) => state.header);

  if (header.loading) {
    return <ContextWrapper theme={theme} node={<Loading />} />;
  }

  if (header.error) {
    return errorPage({
      theme: theme,
      gtmProps: {
        jurisdiction: header.state,
        path: '/refer-error',
        fdAccountId: undefined,
        siteVersion: getGtmSiteVersion,
        loginStatus: 'logged_out',
      },
    });
  }

  return (
    <ReferrerBox
      pageProps={{
        theme: theme,
        gtmProps: {
          jurisdiction: header.state,
          path: '/refer',
          fdAccountId: undefined,
          siteVersion: getGtmSiteVersion,
          loginStatus: 'logged_out',
        },
      }}
      header={header}
    />
  );
};

const ReferrerBox = ({ header, pageProps }: ReferrerProps): JSX.Element => {
  const user: UserDetails = parseToken(header?.token);
  const referrerId = user.sub;

  let variant = undefined;

  if (window.location.hostname.startsWith('refer.casino.')) {
    const EXPERIMENT_KEY = 'racns-531-raf-ab-testing';
    variant = amplitude.getExperimentVariant(EXPERIMENT_KEY);
  }

  const referralOfferId = variant?.payload?.referralOfferId;

  const referralQuery = useGetReferralDetailsByIdQuery({
    referrerId,
    referralOfferId,
  });

  const {
    data: referralDetailsData,
    error: referralDetailsError,
    isLoading: referralDetailsLoading,
  } = referralQuery;

  if (referralDetailsLoading) {
    return <ContextWrapper theme={pageProps.theme} node={<Loading />} />;
  }

  if (referralDetailsData) {
    datadogLogs.logger.info('GET request successful - Referrer Home Page', {
      referrerId,
      referralDetailsData,
    });

    const referralGtmProps: GtmProps = {
      ...pageProps.gtmProps,
      fdAccountId: referrerId,
    };

    tagScreenLoad(referralGtmProps);
    amplitude.log('RAF Hub Page Viewed');

    const homePage = (
      <ReferrerHomePage
        referredUserData={referralDetailsData.referralsForUserDTOList}
        offerData={referralDetailsData.referralOfferDTO}
        link={referralDetailsData.link}
        isMaxReferralsReached={referralDetailsData.isMaxReferralsReached}
      />
    );
    return <ContextWrapper theme={pageProps.theme} node={homePage} />;
  }

  if (referralDetailsError) {
    datadogLogs.logger.error('GET request failed - Referrer Home Page', {
      referrerId,
      referralDetailsError,
    });
  }

  return errorPage({
    theme: pageProps.theme,
    gtmProps: {
      ...pageProps.gtmProps,
      path: '/refer-error',
      fdAccountId: referrerId,
    },
  });
};

export default ReferrerHomeContainer;
