import { ReactNode, useState } from 'react';
import { useTheme } from 'styled-components';
import * as amplitude from '@fanduel/core-analytics';

import { tagIt, setRedirectCookie } from 'LibUtils';
import { useCopy } from 'Contexts';
import { AppName, OfferType, RewardType } from 'Types';

import { Link } from '../Link';
import { Steps } from './Steps';
import {
  Button,
  Container,
  Terms,
  Text,
  TextSection,
} from './LandingHowItWorksStyles';
import { StateSelector } from '../StateSelector/StateSelector';
import { useJoinUrl } from '../../hooks/useJoinUrl';
import { CASINO_STATES, MS_STATE } from '../../constants';

export type LandingHowItWorksProps = {
  sportsbookRefereeRewardAmount: number;
  casinoRefereeRewardAmount: number;
  offerType: OfferType;
  rewardType: RewardType;
  tncUrl: string;
  country: string;
};

export type State = {
  name: string;
  abbreviation: string;
};

interface ShouldShowStateSelectorProps {
  isCasino: boolean
  isMoheganSun: boolean
  isCanada: boolean
}

export const shouldShowStateSelector = ({isCasino, isMoheganSun, isCanada}: ShouldShowStateSelectorProps): boolean => {
  if (isCanada) {
    return false
  }

  return isCasino || isMoheganSun
}

export const getStates = (isMoheganSun: boolean): State[] => isMoheganSun
? [MS_STATE, ...CASINO_STATES]
: CASINO_STATES;

export const LandingHowItWorks = ({
  sportsbookRefereeRewardAmount,
  casinoRefereeRewardAmount,
  offerType,
  rewardType,
  tncUrl,
  country,
}: LandingHowItWorksProps) => {
  const theme = useTheme();
  const copy = useCopy();
  const [selectedState, setSelectedState] = useState<State | undefined>(undefined);
  const isCasino = theme.name === AppName.CASINO;
  const isSportsbook = theme.name === AppName.SPORTSBOOK;
  const isMoheganSun = theme.name === AppName.MOHEGAN_SUN_CASINO;

  const isZeroRecruitCasino = isCasino && casinoRefereeRewardAmount === 0;
  const isZeroRecruitSportsbook =
    isSportsbook && sportsbookRefereeRewardAmount === 0;
  const isCanada = country === 'canada';
  const isBonusSpinsReward = rewardType === 'BONUS_SPINS_REWARD';
  const isBonusSpinsZeroRecruit = isBonusSpinsReward && isZeroRecruitCasino;

  const { buildUrls } = useJoinUrl();

  const joinUrl = buildUrls();

  const [buttonUrl, setButtonUrl] = useState(
    (isCasino && !isCanada) || isMoheganSun ? '' : joinUrl
  );

  const states = getStates(isMoheganSun)

  const onStateSelected = (state: State) => {
    setSelectedState(state)

    const { abbreviation } = state
    const joinUrl = buildUrls(abbreviation, isMoheganSun);
    setButtonUrl(joinUrl);
    setRedirectCookie(abbreviation, isMoheganSun);
  };

  const getHostname = () => {
    try {
      return new URL(tncUrl).hostname.replace('www.', '');
    } catch {
      return 'fanduel';
    }
  };

  const buttonText = copy.joinButton;

  const termsText = (
    <>
      {copy.landingConditionsText}
      <Link
        children={<>{getHostname()}</>}
        href={tncUrl}
        target="_blank"
        fontSize={10}
      />
      .
    </>
  );

  const handleButtonClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!buttonUrl) {
      event.preventDefault();
      return false;
    }

    tagIt('cta_clicked', buttonText, 'refer_a_friend');
    amplitude.log('RAF Recruit CTA Clicked', {
      Module: 'raf_recruit',
      'Link URL': buttonUrl,
      'Link Text': buttonText,
    });
  };

  const paragraphs = copy.landingHowitWorks.split('\n');
  const text: ReactNode[] = paragraphs.map(
    (paragraph: string, index: number) => {
      return (
        <Text key={index} data-testid={index}>
          {paragraph}
        </Text>
      );
    }
  );

  return (
    <Container
      isZeroRecruitCasino={isZeroRecruitCasino}
      data-testid="how-it-works"
    >
      {isBonusSpinsZeroRecruit ? (
        <></>
      ) : (
        copy.landingHowitWorks && <TextSection>{text}</TextSection>
      )}
      {isZeroRecruitCasino ? (
        <></>
      ) : (
        <Steps
          {...{ offerType, rewardType }}
          isZeroRecruitSportsbook={isZeroRecruitSportsbook}
        />
      )}
      { shouldShowStateSelector({isCanada, isCasino, isMoheganSun}) && (
        <StateSelector states={states} onChange={onStateSelected} selectedState={selectedState}/>
      )}
      <Button
        onClick={handleButtonClick}
        href={buttonUrl}
        disabled={!buttonUrl}
        aria-disabled={!buttonUrl}
        data-testid="join-button"
        aria-label={selectedState ? `${buttonText} in: ${selectedState.name}` : buttonText}
      >
        {buttonText}
      </Button>
      <Terms>{termsText}</Terms>
    </Container>
  );
};
